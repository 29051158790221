import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import CMOButton from "../../components/Button/Button";
import { navigate } from "gatsby-link";
import ReactMarkDown from "react-markdown";
import IllustrationDesc from "../../components/IllustrationDesc/IllustrationDesc";
import EllipsItems from "../../components/EllipsItems/EllipsItems";
import TitleDescImg from "../../components/TitleDescImg/TitleDescImg";
import FirstComponent from "../../components/FirstComponent/FirstComponent";
import "./qvik.scss";

const Qvik = (props) => {
  const {
    FirstComponentContent,
    SEO,
    BundlePropositions,
    Component_1,
    Component_2,
    Component_3,
    Component_4,
    Component_5,
    Component_6,
  } = props.pageContext.content;

  console.log( JSON.stringify(FirstComponentContent, null, 2) );
  const location_href = props.location.pathname;

  const Component_4_Content = {
    descImage: `${process.env.GATSBY_CMS_URL}${Component_4.ComponentImage.url}`,
    title: Component_4.Title,
    description: Component_4.Description,
    // button: {
    //   text: Component_4.ButtonText,
    //   link: Component_4.ButtonLink
    // }
  };

  const Component_5_Content = Component_5.ComponentContentItem.map( (itm,idx) => {
    return {
      image:  `${process.env.GATSBY_CMS_URL}${itm.Image.url}`,
      title: itm.Title,
      description: itm.Description,
      button_text: itm.ButtonText,
      url: itm.ButtonLink
    }
  } );

  return (
    <div className="qvik-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#04BDB1"}
        header_secondary_color={"#18EECD"}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
      <FirstComponent
          primary_color={"#04BDB1"}
          secondary_color={"#18EECD"}
          content={{ ...FirstComponentContent }}
          image={FirstComponentContent.image.url}
          image_mobile={FirstComponentContent.image_mobile.url}
          subpage={true}
          big_image
        />
          <div className="component-1-section">
            <TitleDescImg
              title={Component_1.Title}
              description={Component_1.Description}
              imageClass={ "title-desc-img-img" }
              image={ Component_1.Image ? process.env.GATSBY_CMS_URL + Component_1.Image?.url : ""}
              descriptionClass={"title-desc-img-desc"}
            />
          </div>
         {/* <div className="bundles-block">
            <div className="content">
              <h2 className="title">{BundlePropositions.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={BundlePropositions.Description}
                />
              </div>

            </div>
            <div className="bundles-section">
              {BundlePropositions.BundlePropositionItem.map((item, index) => (

                <div className="bundle" key={`bundle-${index}`}
                >
                  <div className="bundle-title" style={{ backgroundColor: item.HeaderBackgroundColor, color: item.HeaderTextColor }} >
                    <h3>{item.Name}</h3>
                  </div>
                  <div className="bundle-content" >
                    <div className="bundle-image">
                      <img src={`${process.env.GATSBY_CMS_URL}${item.BundlePropositionImage?.url}`} alt={item.BundlePropositionImage?.alternativeText} style={{ width: "160px", height: "160px" }} />
                    </div>
                    <div className="bundle-description-wrapper">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.BundlePropositionDescription}
                      />
                    </div>
                  </div>
                  <div className="bundle-links">
                    <CMOButton
                      onClick={() => navigate(item.ButtonLink)}
                      variant="contained"
                      className="bundle-links"
                      background_color={"#5107ff"}
                      text={item.ButtonText}
                      color={"white"} />
                  </div>
                </div>
              ))}
            </div>
          </div>           */}
        <div loading="lazy" 
              className="docs-block" 
              style={{ 
                backgroundImage: `url(${process.env.GATSBY_CMS_URL}${Component_2.BackgroundImage.url})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"}}>
          <LayoutComponent>
            <div className="docs-block-content">
              <div className="docs-text">
                <div className="docs-title">
                  <p>{Component_2.Title}</p>
                  </div>
                <div className="docs-description">
                  <ReactMarkDown
                    rehypePlugins={[rehypeRaw]}
                    children={Component_2.Description}
                  />
                </div>
              </div>
              <CMOButton 
                      background_color={"#5107ff"}
                      color={"#FFFFFF"}
                      text={Component_2.ButtonText} 
                      onClick={ () => {navigate(Component_2.ButtonLink);} } />
            </div>
          </LayoutComponent>
        </div>

        <div className="careAboutYouBlock">
            <div className="careAboutYouBlock-content">
              <h2 className="careAboutYouBlock-content-title">{Component_3.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={Component_3.Description}
                />
              </div>
              <div className="goodnews-wrapper">
                <div className="title">{Component_3.FollowupTitle}</div>
                <ReactMarkdown
                  className="description2"
                  rehypePlugins={[rehypeRaw]}
                  children={Component_3.FollowupDescription}
                />
              </div>
            </div>
          </div>

          <IllustrationDesc  content={Component_4_Content} />
          <EllipsItems content={Component_5_Content}/>

          <TitleDescImg
            title={
              Component_6.Title
            }
            description={
              Component_6.Description
            }
            image={Component_6.Image ? process.env.GATSBY_CMS_URL + Component_6.Image?.url : ""}
            imageClass={"title-desc-img-img"}
            descriptionClass={"title-desc-img-desc"}
            btnText={Component_6.ButtonText}
            btnLink={Component_6.ButtonLink}
          />
      </LayoutHeaderFooter>
    </div>


  );
};

export default Qvik;
